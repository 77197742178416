<template>
  <v-container fluid>
    <v-radio-group v-model="locale" row>
      <v-radio v-for="(locale, index) in localesDictionary" :key="index" :label="locale.name" :value="locale.value"></v-radio>
    </v-radio-group>
    <template v-if="isArticleExist">
      <v-row>
        <v-col cols="12">
          <div class="custom-field">
            <span>ID:</span>
            <span class="font-weight-medium ml-2">{{ additionalInfoArticle.id }}</span>
          </div>
          <div v-if="additionalInfoArticle.name[locale]" class="custom-field">
            <span>Назва статті:</span>
            <span class="font-weight-medium ml-2">{{ additionalInfoArticle.name[locale] }}</span>
          </div>
          <div v-if="additionalInfoArticle.name[locale]" class="custom-field">
            <span>Номер банера:</span>
            <span class="font-weight-medium ml-2">{{ additionalInfoArticle.sort_number }}</span>
          </div>
          <div v-if="additionalInfoArticle.event_code" class="custom-field">
            <span>Назва групи:</span>
            <span class="font-weight-medium ml-2">
              {{ additionalInfoSelectedEventType.name[locale] }} ({{ additionalInfoSelectedEventType.code }})
            </span>
          </div>
          <div v-if="additionalInfoArticle.event_code" class="custom-field">
            <span v-if="additionalInfoArticle.event_code.packet_event_type === 1">Звязано з статусом:</span>
            <span v-else-if="additionalInfoArticle.event_code.packet_event_type === 2">Звязано з подією:</span>
            <span class="font-weight-medium ml-2">
              {{ additionalInfoArticle.event_code.name[locale] }} ({{ additionalInfoArticle.event_code.event_code }}),
              {{ additionalInfoSelectedEventType.name[locale] }} ({{ additionalInfoSelectedEventType.code }})
            </span>
          </div>
          <div v-if="additionalInfoArticle.articles[locale]" class="d-flex">
            <span>Текст статті:</span>
            <div v-html="additionalInfoArticle.articles[locale]" class="ql-editor font-weight-medium ml-2 pa-0"></div>
          </div>
        </v-col>
      </v-row>
      <template v-if="additionalInfoArticle.images && additionalInfoArticle.images.length">
        <div class="mt-5 mb-2">Зображення:</div>
        <v-row>
          <template v-for="(image, index) in additionalInfoArticle.images">
            <v-col :key="index" cols="12" md="4">
              <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 8 : 2" class="pa-5">
                  <v-img :src="`${baseURL}/${image}`" height="225px" contain>
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out v-card--reveal"
                        style="height: 100%; background-color: rgba(0, 0, 0, 60%)"
                      >
                        <v-btn :href="`${baseURL}/${image}`" target="_blank" class="mx-2" fab>
                          <v-icon>mdi-open-in-new</v-icon>
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-card>
              </v-hover>
            </v-col>
          </template>
        </v-row>
      </template>
      <template v-if="additionalInfoArticle.videos && additionalInfoArticle.videos.length">
        <div class="mt-10 mb-2">Відео:</div>
        <v-row>
          <template v-for="(video, index) in additionalInfoArticle.videos">
            <v-col :key="index" cols="12" md="4">
              <v-hover v-slot="{ hover }">
                <v-card :elevation="hover ? 8 : 2" :class="[{ 'on-hover': hover }, 'pa-5']">
                  <video controls width="100%">
                    <source :src="`${baseURL}/${video}`" type="video/mp4" />
                    <source :src="`${baseURL}/${video}`" type="video/ogg" />
                    Your browser does not support the video tag.
                  </video>
                </v-card>
              </v-hover>
            </v-col>
          </template>
        </v-row>
      </template>
    </template>

    <v-container fluid>
      <div class="d-flex justify-end mt-7">
        <v-btn @click="$router.go(-1)" class="mr-2" small>Назад</v-btn>
        <router-link :to="{ name: 'InfoConstructArticle', query: { articleId: this.articleId }, hash: '#edit' }" replace>
          <v-btn color="primary" small>Редагувати</v-btn>
        </router-link>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { isObjectEmpty } from '@/helpers/isObjectEmpty'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import 'quill/dist/quill.core.css'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AdditionalInfoArticleLocaleCard',
  mixins: [],
  components: {},
  props: {
    additionalInfoArticle: {
      type: Object,
      required: true,
      default: () => {},
    },
    additionalInfoSelectedEventType: {
      required: true,
      default: null,
    },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    baseURL: process.env.VUE_APP_BASE_API_URL,
    locale: '',
    loading: false,
  }),

  computed: {
    ...mapState('dictionaries', ['localesDictionary']),
    ...mapGetters('profile', ['getCurrentUserLocale']),

    isArticleExist() {
      return !isObjectEmpty(this.additionalInfoArticle)
    },
    currentCompanyId() {
      return this.$route.params.id
    },
    categoryId() {
      return this.$route.params.categoryId
    },
    articleId() {
      return this.$route.query.articleId
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.locale = this.getCurrentUserLocale
    },
  },
}
</script>

<style scoped lang="scss">
.custom-field {
  max-width: 600px !important;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
